var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from "react";
import React from "react";
import Cookies from "js-cookie";
import sortArray from "sort-array";
var EventsSearchContext = createContext();
export var EventsSearchProvider = function (_a) {
    var _b;
    var children = _a.children;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    //Events State
    var _c = __read(React.useState(0), 2), randQuery = _c[0], setRandQuery = _c[1];
    var _d = __read(React.useState([]), 2), events = _d[0], setEvents = _d[1];
    var _e = __read(React.useState("list"), 2), view = _e[0], setView = _e[1];
    var _f = __read(React.useState(true), 2), eventsIsLoading = _f[0], setEventsIsLoading = _f[1];
    var _g = __read(useState(false), 2), filtersIsLoading = _g[0], setFiltersIsLoading = _g[1];
    var _h = __read(React.useState(true), 2), categoriesIsLoading = _h[0], setCategoriesIsLoading = _h[1];
    var _j = __read(useState({}), 2), rowsSearchData = _j[0], setRowsSearchData = _j[1];
    var _k = __read(useState(false), 2), advSearch = _k[0], setAdvSearch = _k[1];
    //Filter State
    var _l = __read(React.useState([]), 2), categories = _l[0], setCategories = _l[1];
    var _m = __read(React.useState([]), 2), selectedCategory = _m[0], setSelectedCategory = _m[1];
    var _o = __read(React.useState(new Date().getFullYear()), 2), yearInput = _o[0], setYearInput = _o[1];
    var _p = __read(React.useState([]), 2), months = _p[0], setMonths = _p[1];
    //query
    var _q = __read(useState([]), 2), rows = _q[0], setRows = _q[1];
    //Search State
    var _r = __read(React.useState(""), 2), searchInput = _r[0], setSearchInput = _r[1];
    var _s = __read(useState(""), 2), searchedTerm = _s[0], setSearchedTerm = _s[1];
    //events Count
    var _t = __read(React.useState(0), 2), count = _t[0], setCount = _t[1];
    // Pagination State
    var _u = __read(React.useState(0), 2), page = _u[0], setPage = _u[1];
    var _v = __read(React.useState(1), 2), pageCount = _v[0], setPageCount = _v[1];
    // Get fields
    useEffect(function () {
        fetchField();
    }, []);
    var fetchField = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, categories;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setFiltersIsLoading(true);
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/search/fields/?table=Event&context=filters&no_fetching"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    categories = data.filter(function (obj) { return obj.field_name == "categorie"; });
                    setCategories(categories[0].values);
                    setFiltersIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    // highest order
    function getHighestOrder(array) {
        var highestPrice = 99999999;
        for (var i = 0; i < array.length; i++) {
            if (array[i].order > highestPrice) {
                highestPrice = array[i].order;
            }
        }
        return highestPrice;
    }
    //Get Events
    useEffect(function () {
        // Order by the first selected filter
        sortArray(rows, {
            by: ["order"],
        });
        var rowsCopy = __spreadArray([], __read(rows), false);
        // First, check if the first element is not "where"
        if (rowsCopy.length > 0 && rowsCopy[0].condition !== "where") {
            rowsCopy[0].condition = "where";
        }
        var as_date_debut = false;
        var as_date_fin = false;
        if (rowsCopy.length > 0) {
            if (rowsCopy[0].field === "date_debut") {
                as_date_debut = true;
            }
            if (rowsCopy[0].field === "date_fin") {
                as_date_fin = true;
            }
        }
        for (var i = 1; i < rowsCopy.length; i++) {
            if (rowsCopy[i].field !== rowsCopy[i - 1].field) {
                rowsCopy[i].condition = "and";
            }
            if (rowsCopy[i].field === "date_debut") {
                as_date_debut = true;
            }
            if (rowsCopy[i].field === "date_fin") {
                as_date_fin = true;
            }
        }
        if (!as_date_debut || !as_date_fin) {
            var nowdate = new Date();
            nowdate.setDate(nowdate.getDate() - 1);
            if (!as_date_fin) {
                rowsCopy.push({
                    condition: rowsCopy.length > 0 ? "and" : "where",
                    field: "date_fin",
                    operator: "greater",
                    order: 100000001,
                    type: "defaultgreater",
                    value: nowdate.toISOString().slice(0, 10),
                });
            }
        }
        var searchData = {
            query: searchInput !== searchedTerm ? searchInput : searchedTerm,
            page: page + 1,
            filters: [
                {
                    Event: {
                        conditions: rowsCopy,
                        sort: {
                            date_debut: {
                                order: "asc",
                            },
                        },
                    },
                },
            ],
        };
        setRowsSearchData({ conditions: rowsCopy });
        if (!advSearch)
            fetchEvents(searchData);
    }, [rows, page, searchedTerm, randQuery]);
    //Fetch events
    var fetchEvents = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        function postData(url, data) {
            if (url === void 0) { url = "/".concat(langCode, "/api/search/"); }
            if (data === void 0) { data = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch(url, {
                                method: "POST",
                                mode: "cors",
                                cache: "no-cache",
                                credentials: "same-origin",
                                headers: {
                                    "X-CSRFToken": CSRF_TOKEN,
                                    "Content-Type": "application/json",
                                    // 'Content-Type': 'application/x-www-form-urlencoded',
                                },
                                redirect: "follow",
                                referrerPolicy: "no-referrer",
                                body: JSON.stringify(data), // body data type must match "Content-Type" header
                            })];
                        case 1:
                            response = _a.sent();
                            return [2 /*return*/, response.json()];
                    }
                });
            });
        }
        var CSRF_TOKEN;
        return __generator(this, function (_a) {
            setEventsIsLoading(true);
            CSRF_TOKEN = Cookies.get("csrftoken");
            postData("/".concat(langCode, "/api/search/"), data).then(function (data) {
                var nbrPages = data.count / 10;
                setPageCount(Math.ceil(nbrPages));
                var events = data.results.map(function (data) {
                    return data.result;
                });
                setCount(data.count);
                setEvents(events);
                setEventsIsLoading(false);
            });
            return [2 /*return*/];
        });
    }); };
    //Reset filter
    var resetFilters = function () {
        setSelectedCategory([]);
        setMonths([]);
        setYearInput(new Date().getFullYear());
        setRows([]);
        setPage(0);
    };
    //search events
    var handleSearchInput = function (e, tab) {
        if (tab === void 0) { tab = 6; }
        if (tab != 6)
            setAdvSearch(true);
        setSearchInput(e.target.value);
    };
    var searchEvents = function () {
        setPage(0);
        setSearchedTerm(searchInput);
    };
    // handleKeyDown function
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            setPage(0);
            setSearchedTerm(searchInput);
        }
    };
    // View function
    var handleView = function () {
        if (view === "calendar") {
            setView("list");
        }
        else {
            setView("calendar");
        }
    };
    //handle category change
    var handleCategoryChange = function (item) {
        setPage(0);
        //get latest selected
        var latestSelected = selectedCategory
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedCategory.includes(x); }));
        var newCategory = {
            order: 99999999,
            condition: "or",
            field: "categorie.id",
            operator: "is",
            value: latestSelected[0].value,
        };
        if (item.length > selectedCategory.length) {
            setSelectedCategory(item);
            setRows(function (prevStat) {
                return __spreadArray(__spreadArray([], __read(prevStat), false), [newCategory], false);
            });
            if (rows.some(function (obj) { return Object.values(obj).includes("categorie.id"); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "categorie.id"; });
                newCategory.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newCategory.order = bigOrder + 1;
            }
        }
        else {
            //get the removed option
            var removedOption_1 = selectedCategory
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedCategory.includes(x); }));
            setSelectedCategory(item);
            setRows(function () {
                return rows.filter(function (item) {
                    return item.value !== removedOption_1[0].value;
                });
            });
        }
    };
    //Handle Year change
    var handleYear = function (e) {
        setPage(0);
        var newValue = e.target.value;
        var newYear = {
            order: 0,
            type: "year",
            condition: "or",
            field: "date_debut",
            operator: "between",
            value: ["".concat(newValue, "-01-01"), "".concat(newValue, "-12-31")],
        };
        if (newValue != "") {
            setYearInput(newValue);
            if (months.length == 0) {
                if (rows.findIndex(function (row) { return row.field === "date_debut"; }) == -1) {
                    setRows(function (prevStat) { return __spreadArray(__spreadArray([], __read(prevStat), false), [newYear], false); });
                }
                else {
                    var rowsCopy = __spreadArray([], __read(rows), false);
                    var rowIndex = rowsCopy.findIndex(function (row) { return row.field === "date_debut"; });
                    if (rowIndex !== -1) {
                        var rowsCopy_1 = __spreadArray([], __read(rows), false);
                        var rowIndex_1 = rowsCopy_1.findIndex(function (row) { return row.field === "date_debut"; });
                        if (rowIndex_1 !== -1) {
                            rowsCopy_1[rowIndex_1] = __assign(__assign({}, rowsCopy_1[rowIndex_1]), { value: ["".concat(newValue, "-01-01"), "".concat(newValue, "-12-31")] });
                            setRows(rowsCopy_1);
                        }
                    }
                }
            }
            else {
                setRows(function (row) {
                    return row.map(function (obj) {
                        var updatedValue = obj.value
                            ? obj.value.map(function (date) { return date.replace(/^.{4}/, newValue); })
                            : obj.value;
                        return __assign(__assign({}, obj), { value: updatedValue });
                    });
                });
            }
            if (rows.some(function (obj) { return Object.values(obj).includes("date_debut"); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "date_debut"; });
                newYear.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newYear.order = bigOrder + 1;
            }
        }
        else {
            setYearInput("");
            setRows(function () { return rows.filter(function (row) { return row.type != "year"; }); });
        }
    };
    // Handle month change
    var getDaysInMonth = function (month, year) {
        // Create a new date object and set the day to 0
        var date = new Date(year, month, 0);
        // Return the number of days in the month
        return date.getDate();
    };
    var handleMonthChange = function (month) {
        setPage(0);
        //get latest selected
        var latestSelected = months
            .filter(function (x) { return !month.includes(x); })
            .concat(month.filter(function (x) { return !months.includes(x); }));
        var nbrDays = getDaysInMonth(latestSelected[0].value, yearInput);
        var monthNbr = latestSelected[0].value;
        var newMonth = {
            order: 0,
            monthId: monthNbr,
            condition: "or",
            field: "date_debut",
            operator: "between",
            value: [
                "".concat(yearInput, "-").concat(monthNbr < 10 ? "0" : "").concat(monthNbr, "-01"),
                "".concat(yearInput, "-").concat(monthNbr < 10 ? "0" : "").concat(monthNbr, "-").concat(nbrDays),
            ],
        };
        if (month.length > months.length) {
            setMonths(month);
            if (rows.some(function (obj) { return Object.values(obj).includes("date_debut"); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "date_debut"; });
                newMonth.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newMonth.order = bigOrder + 1;
            }
            setRows(function (prevState) {
                return __spreadArray(__spreadArray([], __read(prevState), false), [newMonth], false);
            });
        }
        else {
            //get the removed option
            var removedOption_2 = months
                .filter(function (x) { return !month.includes(x); })
                .concat(month.filter(function (x) { return !months.includes(x); }));
            setMonths(month);
            setRows(function () {
                return rows.filter(function (row) { return row.monthId != removedOption_2[0].value; });
            });
        }
    };
    // Handle page change
    var handlePageClick = function (e) {
        setPage(e.selected);
        window.scrollTo(0, 0);
    };
    return (_jsx(EventsSearchContext.Provider, __assign({ value: {
            setCategories: setCategories,
            handleView: handleView,
            setEvents: setEvents,
            handleSearchInput: handleSearchInput,
            setSearchedTerm: setSearchedTerm,
            setMonths: setMonths,
            setEventsIsLoading: setEventsIsLoading,
            setCategoriesIsLoading: setCategoriesIsLoading,
            searchEvents: searchEvents,
            resetFilters: resetFilters,
            handleKeyDown: handleKeyDown,
            handleYear: handleYear,
            selectedCategory: selectedCategory,
            view: view,
            categories: categories,
            events: events,
            searchInput: searchInput,
            yearInput: yearInput,
            months: months,
            eventsIsLoading: eventsIsLoading,
            filtersIsLoading: filtersIsLoading,
            categoriesIsLoading: categoriesIsLoading,
            handleMonthChange: handleMonthChange,
            handleCategoryChange: handleCategoryChange,
            count: count,
            handlePageClick: handlePageClick,
            page: page,
            pageCount: pageCount,
            rowsSearchData: rowsSearchData,
            setAdvSearch: setAdvSearch,
            setRandQuery: setRandQuery,
        } }, { children: children })));
};
export default EventsSearchContext;
